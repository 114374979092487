import React, { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserDialog } from "./Dialog/UserDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import CustomTooltip from "../../component/CustomTooltip";
import { Button, ButtonGroup } from "react-bootstrap";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import {
  // deleteListUser,
  deleteUser,
  getListUser,
  getUserById,
} from "../services/UserServices";
import { User, UserByOrg } from "../model/UserModels";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import AppContext from "../../../AppContext";
import { PAGINATION, PATH_ROUTES } from "../../../Constant";
import { useNavigate } from "react-router-dom";
import  { OctTable, columnNamesType } from "@oceantech/oceantech-ui"; 
import ConfirmDialog from "../../component/dialog/OCTConfirmDialog";

const UserList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const navigateTo = useNavigate();

  const [userList, setUserList] = useState<User[]>([]);
  const [userInfo, setUserInfo] = useState<UserByOrg>({} as UserByOrg);
  const [id, setId] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  const userDefaultPath = PATH_ROUTES.SYSTEM.USER;

  useEffect(() => {
    if (!openUserDialog) {
      loadListUser();
    }
  }, [searchObject, openUserDialog]);

  const handleEditUser = async (row: any) => {
    handleOpenUserDialog();
    try {
      setPageLoading(true);
      const res = await getUserById(row?.id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        data.roles = data?.roles?.map((item: any) => ({ name: item }));
        const result = {
          ...data,
          user: data,
        };
        navigateTo(userDefaultPath + `/${data?.id}`);
        setUserInfo(result);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      handleOpenUserDialog();
    }
  };

  const handleOpenUserDialog = () => {
    setOpenUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setUserInfo({} as UserByOrg);
    navigateTo(userDefaultPath);
    setIsView(false);
  };

  const loadListUser = async () => {
    try {
      setPageLoading(true);
      const res = await getListUser(searchObject);
      const { data } = res.data;
      if (isSuccessfulResponse(res?.status)) {
        setUserList(data?.content as User[]);
        let totalElementsValue = data?.pageable?.totalElements || 0;
        let totalPageValue =
          totalElementsValue % searchObject?.pageSize === 0
            ? totalElementsValue / searchObject?.pageSize
            : Math.floor(totalElementsValue / searchObject?.pageSize) + 1;
        setTotalPage(totalPageValue || 0);
        setTotalElements(data?.pageable?.totalElements || 0);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      console.error(err);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  // const handleDeleteListUser = async (ids: string[]) => {
  //   try {
  //     const res = await deleteListUser(ids);
  //     if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
  //       toast.success(lang("GENERAL.DELETE_SUCCESS"));
  //       return true;
  //     } else toast.error(`${res?.data?.message}`);
  //   } catch {
  //     toast.error(lang("GENERAL.ERROR"));
  //   } finally {
  //     setPageLoading(false);
  //     await loadListUser();
  //   }
  // };

  const handleDelete = async (id: string) => {
    try {
      setPageLoading(true);
      const res = await deleteUser(id);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      setShouldOpenConfirmDeleteDialog(false);
      await loadListUser();
    }
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>,
    },
    {
      name: lang("USER.NAME"),
      field: "username",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
      type: TYPE.TEXT,
      render: (row) => <span>{row?.username}</span>,
    },
    {
      name: lang("USER.ROLE"),
      field: "roles",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
      type: TYPE.TEXT,
      render: (row) => <span>{row?.roles?.toString()}</span>,
    },
    {
      name: lang("USER.EMAIL"),
      field: "roles",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
      type: TYPE.TEXT,
      render: (row) => <span>{row?.email}</span>,
    },
    {
      name: lang("USER.LAST_LOGIN"),
      field: "lastLoginTime",
      // width: 140,
      headerStyle: {},
      cellStyle: {
        textAlign: "left",
      },
      type: TYPE.DATE,
    },
  ];

  const actionsColumn = [
    {
      name: lang("TABLE.ACTION"),
      field: "action",
      isVisible: true,
      headerStyle: {
        width: 100,
        // ...actionStyle,
      },
      cellStyle: {
        // width: 100,
        // ...actionStyle,
      },
      render: (rowData: any) => (
        <div>
          <ButtonGroup size="sm">
            <CustomTooltip title={lang("BTN.DELETE")}>
              <Button
                onClick={() => {
                  setShouldOpenConfirmDeleteDialog(true);
                  setId(rowData?.id);
                }}
                variant="outline-danger"
                className="hover-white"
              >
                <i className="bi bi-trash-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.EDIT")}>
              <Button
                onClick={() => {
                  setIsView(false);
                  handleEditUser?.(rowData);
                }}
                variant="outline-warning"
                className="hover-white"
              >
                <i className="bi bi-pencil-fill px-0"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.VIEW")}>
              <Button
                onClick={() => {
                  setIsView(true);
                  handleEditUser?.(rowData);
                }}
                variant="outline-primary"
                className="hover-white"
              >
                <i className="bi bi-eye-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  return (
    <div>
      <OctTable
        title={lang("USER.LIST")}
        data={userList}
        columns={columns}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        isActionTableTab
        buttonAdd
        scrollable
        isSearchable
        handleSearch={loadListUser}
        notDelete
        handleOpenDialog={handleOpenUserDialog}
        totalPages={totalPage}
        totalElements={totalElements}
        type={TYPE.MULTILINE}
        actionsColumn={actionsColumn}
        className="custom-list-data"
      />
      {openUserDialog && (
        <UserDialog
          isView={isView}
          handleCloseDialog={handleCloseUserDialog}
          userInfo={userInfo}
        />
      )}
      {shouldOpenConfirmDeleteDialog && (
        <ConfirmDialog
          show={shouldOpenConfirmDeleteDialog}
          title={"Xác nhận xóa"}
          message={"Bạn có muốn xóa không?"}
          yes={"Xác nhận"}
          onYesClick={() => handleDelete(id)}
          cancel={"Hủy"}
          onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
        />
      )}
    </div>
  );
};
export { UserList };
