import { FC, useContext, useEffect, useState } from "react";
import { PersonModels } from "./model/PersonModels";
import { toast } from "react-toastify";
import { PersonDialog } from "./component/Dialog/PersonDialog";
import { OctTable, columnNamesType } from "@oceantech/oceantech-ui";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { TYPE } from "../utils/Constant";
import { IObject } from "../models/models";
import { INIT_SEARCH_OBJECT } from "../constant";
import {
  deletePerson,
  getListPersons,
  getPersonById,
} from "./services/PersonServices";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../AppFunction";
import { PAGINATION } from "../../Constant";
import AppContext from "../../AppContext";
import { INIT_PERSON } from "./const/PersonConst";
import { Button, ButtonGroup } from "react-bootstrap";
import CustomTooltip from "../component/CustomTooltip";
import ConfirmDialog from "../component/dialog/OCTConfirmDialog";

const Person: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [personListData, setPersonListData] = useState<PersonModels[]>([]);
  const [personInfo, setPersonInfo] = useState<PersonModels>(INIT_PERSON);
  const [openUnitDialog, setOpenUnitDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [isView, setIsView] = useState<boolean>(false);

  useEffect(() => {
    if (!openUnitDialog) {
      HandleGetListPerson();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize, openUnitDialog]);

  const handleOpenPersonDialog = () => {
    setOpenUnitDialog(true);
  };

  const handleCloseUnitDialog = () => {
    setOpenUnitDialog(false);
    setPersonInfo(INIT_PERSON);
  };

  const HandleGetListPerson = async () => {
    try {
      setPageLoading(true);
      const res = await getListPersons(searchObject);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setPersonListData(data?.content as PersonModels[]);
        setTotalPage(data?.totalPages || 0);
        setTotalElements(data?.pageable?.totalElements || 0);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleEditPerson = async (row: any) => {
    try {
      setPageLoading(true);
      const res = await getPersonById(row?.id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        data.roles = data?.roles?.map((item: any) => ({ name: item }));
        const result = {
          ...data,
          user: data,
        };

        setPersonInfo(result);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      handleOpenPersonDialog();
    }
  };

  const handleDeletePerson = async (id: any) => {
    try {
      setPageLoading(true);
      const res = await deletePerson(id);
      const { code } = res?.data;
      if (isSuccessfulResponse(code)) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      await HandleGetListPerson();
    }
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>,
    },
    {
      name: lang("WELFARE.EMPLOYEE.FULLNAME"),
      field: "fullName",
      headerStyle: {
        minWidth: "150px",
      },
    },
    {
      name: lang("USER.EMAIL"),
      field: "email",
      headerStyle: {
        minWidth: "150px",
      },
    },
    {
      name: lang("USER.PHONE"),
      field: "email",
      headerStyle: {
        minWidth: "150px",
      },
    },
  ];

  const actionsColumn = [
    {
      name: lang("TABLE.ACTION"),
      field: "action",
      isVisible: true,
      headerStyle: {
        width: 100,
      },
      cellStyle: {
      },
      render: (rowData: any) => (
        <div>
          <ButtonGroup size="sm">
            {/* <CustomTooltip title={lang("BTN.DELETE")}>
              <Button
                onClick={() => {
                  setShouldOpenConfirmDeleteDialog(true);
                  setId(rowData?.id);
                }}
                variant="outline-danger"
                className="hover-white"
              >
                <i className="bi bi-trash-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip> */}
            <CustomTooltip title={lang("BTN.EDIT")}>
              <Button
                onClick={() => {
                  setIsView(false);
                  handleEditPerson(rowData);
                }}
                variant="outline-warning"
                className="hover-white"
              >
                <i className="bi bi-pencil-fill px-0"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.VIEW")}>
              <Button
                onClick={() => {
                  setIsView(true);
                  handleEditPerson(rowData);
                }}
                variant="outline-primary"
                className="hover-white"
              >
                <i className="bi bi-eye-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  return (
    <div>
      <OctTable
        title={lang("USER.INFOR")}
        isActionTableTab
        data={personListData}
        columns={columns}
        isSearchable
        // buttonEdit
        buttonAdd={true}
        handleOpenDialog={handleOpenPersonDialog}
        handleSearch={HandleGetListPerson}
        totalPages={totalPage}
        totalElements={totalElements}
        unSelectedAll={true}
        type={TYPE.MULTILINE}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        // handleEdit={handleEditPerson}
        handleDelete={handleDeletePerson}
        handleDoubleClick={handleEditPerson}
        actionsColumn={actionsColumn}
        className="custom-list-data"
      />
      {openUnitDialog && (
        <PersonDialog
          isView={isView}
          handleCloseDialog={handleCloseUnitDialog}
          personInfo={personInfo}
          handleReload={HandleGetListPerson}
        />
      )}

      {shouldOpenConfirmDeleteDialog && (
        <ConfirmDialog
          show={shouldOpenConfirmDeleteDialog}
          title={lang("DIALOG.CONFIRM.DELETE.TITLE")}
          message={lang("PERSON.DELETE.CONFIRM")}
          yes={lang("BTN.CONFIRM")}
          onYesClick={() => handleDeletePerson(id)}
          cancel={lang("BTN.CANCEL")}
          onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
        />
      )}
    </div>
  );
};
export { Person };
