import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  generateForm,
  handleThrowResponseMessage,
} from "../../../../AppFunction";
import {
  FORM_CODE_SERVICE,
  FORM_CODE_SERVICE_ARRAY,
  LIST_ATTRIBUTES,
  LIST_ATTRIBUTES_ARRAY,
} from "../../../constant";
import AppContext from "../../../../AppContext";
import { RESPONSE_STATUS_CODE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import {
  addNewService,
  getListDepartment,
  getListServiceType,
  updateService,
} from "../../services/ManagerServices";
import { Formik, Form } from "formik";
import TabMenu from "../../../component/tabs/TabMenu";
import FormService from "./Forms/FormService";
import { isSuccessfulResponse } from "../../../component/generate-form/GenerateFormConfig";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import * as Yup from "yup";

type Props = {
  handleCloseDialog: () => void;
  isView?: boolean;
  serviceInfo: any;
};

const initialValuesService: any = {
  serviceInfo: {
    name: "",
    code: "",
    svcFeeIns: "",
    svcFeeHospital: "",
    svcFee: "",
    class: null,
    deptLaboratory: null,
  },
};

const ServiceDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { handleCloseDialog, isView = false, serviceInfo } = props;
  const { setPageLoading } = useContext(AppContext);
  const [dataGenerate, setDataGenerate] = useState();
  const [dataDichVu, setDataDichVu] = useState({ values: {}, id: null });
  const [currentTab, setCurrentTab] = useState(0);
  const formikRef = useRef<any>(null);

  let validationSchema = Yup.object({
    serviceInfo: Yup.object({
      name: Yup.string().required("Bắt buộc nhập").nullable(),
      code: Yup.string().required("Bắt buộc nhập").nullable(),
      svcFeeIns: Yup.string().required("Bắt buộc nhập").nullable(),
      svcFeeHospital: Yup.string().required("Bắt buộc nhập").nullable(),
      svcFee: Yup.string().required("Bắt buộc nhập").nullable(),
      class: Yup.object({}).required("Bắt buộc nhập").nullable(),
      deptLaboratory: Yup.object({}).required("Bắt buộc nhập").nullable(),
    }),
  });

  const getForm = async (code: string) => {
    let data = await generateForm(code, setPageLoading);
    setDataGenerate(data);
  };

  const [listServiceType, setListServiceType] = useState<any>([]);
  const [listDepartment, setListDepartment] = useState<any>([]);

  const handleGetListServiceType = async () => {
    try {
      let { data } = await getListServiceType();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListServiceType(data?.data?.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetListDepartment = async () => {
    try {
      let { data } = await getListDepartment({pageIndex: 1, pageSize: 99999});
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListDepartment(data?.data?.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetListServiceType();
    handleGetListDepartment();
  }, []);

  useEffect(() => {
    getForm(FORM_CODE_SERVICE_ARRAY[currentTab]?.code);
  }, [currentTab]);

  const convertSubmitData = (values: any) => {
    let lstAttributesSubmit: any = [];

    LIST_ATTRIBUTES_ARRAY.map((item: any) => {
      if (values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.[item.name]) {
        let attrCurrent = values?.[
          FORM_CODE_SERVICE_ARRAY[currentTab].code
        ]?.lstAttributes?.find((itemAtr: any) => {
          return itemAtr?.attributeTypeId === item?.code;
        });
        lstAttributesSubmit.push(
          attrCurrent
            ? {
                ...attrCurrent,
                attributeTypeId: item.code,
                valueReference:
                  values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.[item.name],
              }
            : {
                attributeTypeId: item.code,
                valueReference:
                  values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.[item.name],
              }
        );
      }
    });
    return {
      term: {
        id: values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.id,
        code: values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.code,
        name: values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.name,
        fullfillDeptName: null,
        isSet: false,
        classId: values?.[FORM_CODE_SERVICE_ARRAY[currentTab].code]?.classId,
      },
      lstAttributes: lstAttributesSubmit,
    };
  };

  const handleFormSubmit = async (values: any) => {
    const data: any = convertSubmitData(values);

    try {
      const res: any = values?.[FORM_CODE_SERVICE_ARRAY[currentTab]?.code]?.id
        ? await updateService(
            values?.[FORM_CODE_SERVICE_ARRAY[currentTab]?.code]?.id,
            data
          )
        : await addNewService(data);
      if (isSuccessfulResponse(res?.data?.code)) {
        toast.success(
          serviceInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const getDataPByServiceId = (userId: string, serviceInfo: any) => {
    let department: any = null
    serviceInfo?.lstAttributes?.map((item: any) => {
      let nameAttr = LIST_ATTRIBUTES_ARRAY.find((itemAttr: any) => {
        return +itemAttr.code === +item.attributeTypeId;
      })?.name;
      if (nameAttr) {
        serviceInfo[nameAttr] = item?.valueReference;
      }

      // Map lấy ra thông tin department bằng data GET
      if(item?.attributeTypeId === LIST_ATTRIBUTES.deptLaboratoryCode.code) {
        department = listDepartment?.find((itemDepartment: any) => itemDepartment?.code === item?.valueReference)
        serviceInfo.deptLaboratoryName = department?.name
        serviceInfo.deptLaboratoryCode = department?.code
        serviceInfo.deptLaboratory = department
      }
    });
    serviceInfo.class = listServiceType?.find(
      (itemService: any) => itemService?.id === serviceInfo?.classId
    );
    
    formikRef?.current?.setValues({
      [FORM_CODE_SERVICE.DICH_VU_KY_THUAT.code]: { ...serviceInfo },
    });
  };

  useEffect(() => {
    if (serviceInfo?.id && listDepartment?.length > 0) {
      getDataPByServiceId(serviceInfo?.id, serviceInfo);
    }
  }, [serviceInfo, listDepartment]);

  return (
    <>
      <Modal
        className="modal-service"
        show={true}
        onHide={handleCloseDialog}
        size="xl"
      >
        <Formik<any>
          initialValues={{ ...initialValuesService }}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            errors,
            touched,
            resetForm,
          }) => {
            formikRef.current = {
              values: values,
              setFieldValue: setFieldValue,
              setValues: setValues,
            };

            const danhSachTab = [
              {
                eventKey: "10",
                title: "Thông tin dịch vụ",
                component: (
                  <FormService
                    isView={isView}
                    listFieldAuto={dataGenerate}
                    handleSave={handleSubmit}
                    itemEdit={dataDichVu.values}
                    errors={errors}
                    touched={touched}
                    formName={FORM_CODE_SERVICE.DICH_VU_KY_THUAT.code}
                  />
                ),
              },
              {
                eventKey: "11",
                title: "Bảng giá yêu cầu",
                component: (
                  <FormService
                    isView={isView}
                    listFieldAuto={dataGenerate}
                    handleSave={handleSubmit}
                    itemEdit={dataDichVu.values}
                    errors={errors}
                    touched={touched}
                    formName={FORM_CODE_SERVICE.BANG_GIA_YEU_CAU.code}
                  />
                ),
              },
              {
                eventKey: "11",
                title: "Thông tin khác",
                component: (
                  <FormService
                    isView={isView}
                    listFieldAuto={dataGenerate}
                    handleSave={handleSubmit}
                    itemEdit={dataDichVu.values}
                    errors={errors}
                    touched={touched}
                    formName={FORM_CODE_SERVICE.THONG_TIN_KHAC.code}
                  />
                ),
              },
            ];
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Cập nhật dịch vụ
                    {/* <i
                className={`bi ${
                  isView
                    ? "bi-eye"
                    : serviceInfo?.id
                    ? "bi-pencil-square"
                    : " bi-plus-circle"
                } text-primary ps-2 fs-5`}
              ></i> */}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="spaces p-6">
                  <TabMenu
                    danhsachTabs={danhSachTab}
                    handlerSelect={(activeTab: any) =>
                      setCurrentTab(+activeTab)
                    }
                  />
                </Modal.Body>
                <Modal.Footer className="">
                  <div className="flex bg-white spaces border-top justify-content-end p-0">
                    {!isView && (
                      <Button
                        className="btn-fill spaces min-w-86"
                        onClick={() => handleSubmit()}
                      >
                        <span>Lưu lại</span>
                      </Button>
                    )}
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ServiceDialog;
