import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML";
import { useFormikContext } from "formik";

type Props = {
  isView: boolean;
  handleSave: (values: any) => void;
  listFieldAuto: any;
  itemEdit: any;
  formName: string;
  errors?: any;
  touched?: any;
};

export default function FormService({
  handleSave,
  isView,
  listFieldAuto,
  itemEdit,
  formName,
  errors,
  touched,
}: Props) {
  const { values, setFieldValue } = useFormikContext<any>();
  const handleChange = (name: string, value: any, type?: string) => {
    if (type === "select" && name === "class") {
      let stringId = name + "Id";
      setFieldValue(formName, {
        ...values?.[formName],
        [stringId]: value?.id,
        [name]: value
      });
    } else if (type === "select") {
      let stringName = name + "Name";
      let stringCode = name + "Code";
      setFieldValue(formName, {
        ...values?.[formName],
        [stringName]: value?.name,
        [stringCode]: value?.code,
        [name]: value
      });
    } else {
      setFieldValue(formName, {
        ...values?.[formName],
        [name]: value,
      });
    }
  };

  return (
    <GenerateFormHTML
      isView={isView}
      listFieldAuto={listFieldAuto}
      handleSave={handleSave}
      itemEdit={itemEdit}
      isSave={false}
      isUpdate={true}
      onChange={handleChange}
      values={values}
      modelID={formName}
      errors={errors}
      touched={touched}
    />
  );
}
