import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import React, { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../component/dialog/OCTConfirmDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { PAGINATION, PATH_ROUTES } from "../../../Constant";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import { Button, ButtonGroup } from "react-bootstrap";
import CustomTooltip from "../../component/CustomTooltip";
import ServiceDialog from "./Dialog/ServiceDialog";
import {
  deleteService,
  getDSDichVuKyThuat,
  getServiceById,
} from "../services/ManagerServices";
import AppContext from "../../../AppContext";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { convertNumberPrice } from "../../utils/FunctionUtils";
import ButtonImportExcel from "../../component/ButtonImportExcel";

type Props = {};

const ServiceList = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [serviceList, setServiceList] = useState<any[]>([]);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [id, setId] = useState<string>("");
  const [openServiceDialog, setOpenServiceDialog] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const navigateTo = useNavigate();
  const [serviceInfo, setServiceInfo] = useState<any>({});

  const serviceDefaultPath = PATH_ROUTES.SYSTEM.SERVICE;

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>,
    },
    {
      name: "Mã dịch vụ",
      field: "code",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
      // render: (row) => <span>{row?.username}</span>,
    },
    {
      name: "Tên dịch vụ",
      field: "name",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
      // render: (row) => <span>{row?.roles?.toString()}</span>,
    },
    {
      name: "Đơn vị",
      field: "unit",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: "left",
      },
      // render: (row) => <span>{row?.email}</span>,
    },
    {
      name: "Giá bảo hiểm",
      field: "svcFeeIns",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.svcFeeIns)}</span>,
    },
    {
      name: "Giá viện phí",
      field: "svcFeeHospital",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.svcFeeHospital)}</span>,
    },
    {
      name: "Giá dịch vụ",
      field: "svcFee",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.svcFee)}</span>,
    },
    {
      name: "Phòng thực hiện",
      field: "fullfillDeptName",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: "Khu thực hiện",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  const actionsColumn: any[] = [
    {
      name: lang("TABLE.ACTION"),
      field: "action",
      isVisible: true,
      headerStyle: {
        width: 100,
        // ...actionStyle,
      },
      cellStyle: {
        // width: 100,
        // ...actionStyle,
      },
      render: (rowData: any) => (
        <div>
          <ButtonGroup size="sm">
            <CustomTooltip title={lang("BTN.DELETE")}>
              <Button
                onClick={() => {
                  setShouldOpenConfirmDeleteDialog(true);
                  setId(rowData?.id);
                }}
                variant="outline-danger"
                className="hover-white"
              >
                <i className="bi bi-trash-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.EDIT")}>
              <Button
                onClick={() => {
                  setIsView(false);
                  handleEditService?.(rowData);
                }}
                variant="outline-warning"
                className="hover-white"
              >
                <i className="bi bi-pencil-fill px-0"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.VIEW")}>
              <Button
                onClick={() => {
                  setIsView(true);
                  handleEditService?.(rowData);
                }}
                variant="outline-primary"
                className="hover-white"
              >
                <i className="bi bi-eye-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const handleGetServiceList = async () => {
    try {
      setPageLoading(true);
      const { data } = await getDSDichVuKyThuat(searchObject);
      if (RESPONSE_STATUS_CODE.SUCCESS === data.code) {
        setServiceList(data?.data?.content);
        let totalElementsValue = data?.data?.totalElements || 0;
        let totalPageValue =
          totalElementsValue % searchObject?.pageSize === 0
            ? totalElementsValue / searchObject?.pageSize
            : Math.floor(totalElementsValue / searchObject?.pageSize) + 1;
        setTotalPage(totalPageValue || 0);
        setTotalElements(data?.data?.totalElements || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    handleGetServiceList();
  }, [searchObject]);

  const handleEditService = async (row: any) => {
    handleOpenServiceDialog();
    try {
      setPageLoading(true);
      const { data } = await getServiceById(row?.id);
      if (isSuccessfulResponse(data?.code)) {
        setServiceInfo(data?.data);
      } else {
        handleThrowResponseMessage(data);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      handleOpenServiceDialog();
    }
  };

  const loadListService = async () => {
    // Your logic to fetch data
  };

  const handleOpenServiceDialog = () => {
    setOpenServiceDialog(true);
  };

  const handleDelete = async (id: string) => {
    try {
      setPageLoading(true);
      const res = await deleteService(id);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      setShouldOpenConfirmDeleteDialog(false);
      await handleGetServiceList();
    }
  };

  const handleCloseServiceDialog = () => {
    setOpenServiceDialog(false);
    setServiceInfo({});
    setIsView(false);
    handleGetServiceList();
  };

  const handleExportExcel = () => {};
  const rowElement = document.querySelector(".table-toolbar .row .flex-end");
  const newDiv = document.querySelector(".btn-export-excel");
  if (rowElement && newDiv) {
    rowElement.insertBefore(newDiv, rowElement.children[rowElement.children.length]);
  }

  return (
    <div>
      <OctTable
        title={lang("Danh sách dịch vụ kỹ thuật")}
        data={serviceList}
        columns={columns}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        isActionTableTab
        buttonAdd
        scrollable
        isSearchable
        handleSearch={loadListService}
        notDelete
        handleOpenDialog={handleOpenServiceDialog}
        totalPages={totalPage}
        totalElements={totalElements}
        type={TYPE.MULTILINE}
        actionsColumn={actionsColumn}
        className="custom-list-data"
        buttonExportExcel
        handleExportExcel={handleExportExcel}
      />
      <ButtonImportExcel
        className="btn-export-excel"
        url="/terms/import"
        handleOKEditClose={handleGetServiceList}
      />
      {openServiceDialog && (
        <ServiceDialog
          isView={isView}
          handleCloseDialog={handleCloseServiceDialog}
          serviceInfo={serviceInfo}
        />
      )}
      {shouldOpenConfirmDeleteDialog && (
        <ConfirmDialog
          show={shouldOpenConfirmDeleteDialog}
          title={"Xác nhận xóa"}
          message={"Bạn có muốn xóa không?"}
          yes={"Xác nhận"}
          onYesClick={() => handleDelete(id)}
          cancel={"Hủy"}
          onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
        />
      )}
    </div>
  );
};

export default ServiceList;
