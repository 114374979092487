import { useField, useFormikContext } from "formik";
import { Form, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { forwardRef, useEffect, useRef } from "react";
import { TYPE } from "../../utils/Constant";

interface TextFieldProps {
  labelClassName?: string;
  inputClassName?: string;
  [key: string]: any;
}

const TextField: React.FC<TextFieldProps> = forwardRef<
  HTMLInputElement,
  TextFieldProps
>(({ labelClassName, inputClassName, readOnlyText, labelWidth = 0, ...props }, ref) => {
  const [field, meta] = useField(props?.name);
  const { submitCount, isSubmitting } = useFormikContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const renderTooltip = (props: any) =>
    (meta.error && meta.touched) || (props?.errors && props?.touched) ? (
      <Tooltip id="button-tooltip" {...props}>
        <div className="text-danger">
          {meta.error ? meta.error : props?.errors}
        </div>
      </Tooltip>
    ) : (
      <div></div>
    );

  useEffect(() => {
    if ((meta.error && meta.touched) || submitCount > 0 || isSubmitting) {
      const invalidElements = document.querySelectorAll(".is-invalid");
      if (invalidElements.length > 0) {
        const firstInvalidElement = invalidElements[0] as HTMLElement;
        firstInvalidElement.focus();
        firstInvalidElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [submitCount, isSubmitting]);

  return (
    <div className={`text-field-v2 ${props?.className} ${props?.labelPosition === 'left-left' ? "d-flex" : ""}`}>
      {props?.label && (
        <span
          className={`text-lable-input max-content-width ${
            labelClassName ? labelClassName : "me-2"
          }`}
        >
          {props?.label}
          {props?.isRequired && <span className="text-danger"> (*)</span>}
        </span>
      )}
      <Form.Group className={`position-relative spaces width-${100 - Number(labelWidth)}`}>
        <FormControl
          {...field}
          {...props}
          ref={ref || inputRef}
          readOnly={readOnlyText}
          className={`spaces px-4 ${inputClassName ? inputClassName : ""} ${
            (meta.error && meta.touched) || (props?.errors && props?.touched) || (meta.touched && props?.errors)
              ? "is-invalid"
              : ""
          } form-control customs-input ${
            props?.className ? props?.className : ""
          } ${
            props?.as === TYPE.TEXTAREA && props?.className
              ? props?.className
              : ""
          }`}
        />
        <Form.Control.Feedback
          type="invalid"
          tooltip
          className="field-tooltip-error"
        >
          {props.errors || meta.error}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
});

export default TextField;