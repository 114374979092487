import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  generateForm,
  handleThrowResponseMessage,
} from "../../../../AppFunction";
import { LIST_ATTRIBUTES, LIST_ATTRIBUTES_ARRAY } from "../../../constant";
import AppContext from "../../../../AppContext";
import { RESPONSE_STATUS_CODE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import {
  addNewService,
  getListDepartment,
  getListServiceType,
  updateService,
} from "../../services/ManagerServices";
import { Formik, Form, useFormikContext } from "formik";
import { isSuccessfulResponse } from "../../../component/generate-form/GenerateFormConfig";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import * as Yup from "yup";
import GenerateFormHTML from "../../../component/generate-form/GenerateFormHTML";

type Props = {
  handleCloseDialog: () => void;
  isView?: boolean;
  supplierInfo: any;
};

const initialValuesService: any = {
  supplierInfo: {
    name: "",
    code: "",
    svcFeeIns: "",
    svcFeeHospital: "",
    svcFee: "",
    class: null,
    deptLaboratory: null,
  },
};

const SupplierDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { handleCloseDialog, isView = false, supplierInfo } = props;
  const { setPageLoading } = useContext(AppContext);
  const [dataGenerate, setDataGenerate] = useState();
  const [dataDichVu, setDataDichVu] = useState({ values: {}, id: null });
  const formikRef = useRef<any>(null);

  let validationSchema = Yup.object({
    supplierInfo: Yup.object({
      name: Yup.string().required("Bắt buộc nhập").nullable(),
      code: Yup.string().required("Bắt buộc nhập").nullable(),
      svcFeeIns: Yup.string().required("Bắt buộc nhập").nullable(),
      svcFeeHospital: Yup.string().required("Bắt buộc nhập").nullable(),
      svcFee: Yup.string().required("Bắt buộc nhập").nullable(),
      class: Yup.object({}).required("Bắt buộc nhập").nullable(),
      deptLaboratory: Yup.object({}).required("Bắt buộc nhập").nullable(),
    }),
  });

  const getForm = async (code: string) => {
    let data = await generateForm(code, setPageLoading);
    setDataGenerate(data);
  };

  const [listServiceType, setListServiceType] = useState<any>([]);
  const [listDepartment, setListDepartment] = useState<any>([]);

  const handleGetListServiceType = async () => {
    try {
      let { data } = await getListServiceType();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListServiceType(data?.data?.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetListDepartment = async () => {
    try {
      let { data } = await getListDepartment({ pageIndex: 1, pageSize: 99999 });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListDepartment(data?.data?.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetListServiceType();
    handleGetListDepartment();
  }, []);

  useEffect(() => {
    getForm("supplier");
  }, []);

  const convertSubmitData = (values: any) => {
    let lstAttributesSubmit: any = [];

    LIST_ATTRIBUTES_ARRAY.map((item: any) => {
      if (values?.serviceInfo?.[item.name]) {
        let attrCurrent = values?.serviceInfo?.lstAttributes?.find(
          (itemAtr: any) => {
            return itemAtr?.attributeTypeId === item?.code;
          }
        );
        lstAttributesSubmit.push(
          attrCurrent
            ? {
                ...attrCurrent,
                attributeTypeId: item.code,
                valueReference: values?.serviceInfo?.[item.name],
              }
            : {
                attributeTypeId: item.code,
                valueReference: values?.serviceInfo?.[item.name],
              }
        );
      }
    });
    return {
      term: {
        id: values?.serviceInfo?.id,
        code: values?.serviceInfo?.code,
        name: values?.serviceInfo?.name,
        fullfillDeptName: null,
        isSet: false,
        classId: values?.serviceInfo?.classId,
      },
      lstAttributes: lstAttributesSubmit,
    };
  };

  const handleFormSubmit = async (values: any) => {
    const data: any = convertSubmitData(values);

    try {
      const res: any = values?.serviceInfo?.id
        ? await updateService(values?.serviceInfo?.id, data)
        : await addNewService(data);
      if (isSuccessfulResponse(res?.data?.code)) {
        toast.success(
          supplierInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const getDataPByServiceId = (userId: string, supplierInfo: any) => {
    let department: any = null;
    supplierInfo?.lstAttributes?.map((item: any) => {
      let nameAttr = LIST_ATTRIBUTES_ARRAY.find((itemAttr: any) => {
        return +itemAttr.code === +item.attributeTypeId;
      })?.name;
      if (nameAttr) {
        supplierInfo[nameAttr] = item?.valueReference;
      }

      // Map lấy ra thông tin department bằng data GET
      if (item?.attributeTypeId === LIST_ATTRIBUTES.deptLaboratoryCode.code) {
        department = listDepartment?.find(
          (itemDepartment: any) => itemDepartment?.code === item?.valueReference
        );
        supplierInfo.deptLaboratoryName = department?.name;
        supplierInfo.deptLaboratoryCode = department?.code;
        supplierInfo.deptLaboratory = department;
      }
    });
    supplierInfo.class = listServiceType?.find(
      (itemService: any) => itemService?.id === supplierInfo?.classId
    );

    formikRef?.current?.setValues({
      supplierInfo: { ...supplierInfo },
    });
  };

  useEffect(() => {
    if (supplierInfo?.id && listDepartment?.length > 0) {
      getDataPByServiceId(supplierInfo?.id, supplierInfo);
    }
  }, [supplierInfo, listDepartment]);

  return (
    <>
      <Modal
        className="modal-supplier"
        show={true}
        onHide={handleCloseDialog}
        size="xl"
      >
        <Formik<any>
          initialValues={{ ...initialValuesService }}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            errors,
            touched,
            resetForm,
          }) => {
            formikRef.current = {
              values: values,
              setFieldValue: setFieldValue,
              setValues: setValues,
            };
            const handleChange = (name: string, value: any, type?: string) => {
              if (type === "select" && name === "class") {
                let stringId = name + "Id";
                setFieldValue("supplierInfo", {
                  ...values?.["supplierInfo"],
                  [stringId]: value?.id,
                  [name]: value,
                });
              } else if (type === "select") {
                let stringName = name + "Name";
                let stringCode = name + "Code";
                setFieldValue("supplierInfo", {
                  ...values?.["supplierInfo"],
                  [stringName]: value?.name,
                  [stringCode]: value?.code,
                  [name]: value,
                });
              } else {
                setFieldValue("supplierInfo", {
                  ...values?.["supplierInfo"],
                  [name]: value,
                });
              }
            };
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Cập nhật nhà cung cấp</Modal.Title>
                </Modal.Header>
                <Modal.Body className="spaces p-20">
                  <GenerateFormHTML
                    isView={isView}
                    listFieldAuto={dataGenerate}
                    handleSave={handleSubmit}
                    itemEdit={dataDichVu.values}
                    isSave={false}
                    isUpdate={true}
                    onChange={handleChange}
                    values={values}
                    modelID={"supplierInfo"}
                    errors={errors}
                    touched={touched}
                  />
                </Modal.Body>
                <Modal.Footer className="">
                  <div className="flex bg-white spaces border-top justify-content-end p-0">
                    {!isView && (
                      <Button
                        className="btn-fill spaces min-w-86"
                        onClick={() => handleSubmit()}
                      >
                        <span>Lưu lại</span>
                      </Button>
                    )}
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default SupplierDialog;
