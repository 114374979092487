import axios from "axios";
import { paramsConfig } from "../../../modules/utils/ParamsUtils";
import { localStorageItem } from "../../../modules/utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../modules/auth/core/_consts";

const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_PHONG_BAN = API_PATH + "/c-department";

export const getEmployeeIdByUserId = (id: any) => {
  let url = `${API_PATH}/employee/get-by-user-id/${id}`;
  return axios.get(url);
};

export const searchByPage = (searchObject: any) => {
  let url = API_PATH + "/employee/page";
  return axios.get(url, paramsConfig(searchObject));
};

export const searchAllEmployee = (searchObject: any = {}) => {
  let url = API_PATH + "/employee/page";
  return axios.get(url, paramsConfig(searchObject));
};

export const searchPhongBan = (searchObj: any) => {
  return axios.post(`${API_PHONG_BAN}/page`, searchObj);
};