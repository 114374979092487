import axios, {AxiosResponse} from "axios";
import {APIResponse} from "../../models/models";
import {PersonModels} from "../model/PersonModels";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";


const API_URL_ORG = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_XADMIN_ENDPOINT) + "/person";

export const getListPersons = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || 10,
            pageIndex: searchData?.pageIndex || 1,
        },
    };
    let url = `${API_URL_ORG}/search`;
    return axios.get(url, config);
};

export const updateOrganization = (id: string, org: PersonModels): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.put(url, org);
};

export const addNewOrganization = (org: PersonModels): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}`;
    return axios.post(url, org);
};

export const getPersonById = (id?: any) => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.get(url);
};

export const deleteListOrganization = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params :{
            ids:ids?.toString()
        }
    }
    let url = `${API_URL_ORG}/list-id`;
    return axios.delete(url,config);
};

export const deletePerson = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.delete(url);
};

export const addNewPerson = (user: PersonModels): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}`;
    return axios.post(url, user);
};

export const updatePerson = (id: string, user: PersonModels): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.put(url, user);
};