import axios, {AxiosResponse} from "axios";
import {APIResponse} from "../../models/models";
import {UserByOrg} from "../model/UserModels";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";


const API_URL_USER = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"] || process.env.REACT_APP_API_URL_SSO;
const API_URL_ADMIN = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_XADMIN_ENDPOINT;

export const getListUser = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || 10,
            pageIndex: searchData?.pageIndex || 1,
        },
    };
    let url = `${API_URL_USER}/users/search`;
    return axios.get(url, config);
};

export const updateUser = (id: string, user: UserByOrg): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users/${id}`;
    return axios.put(url, user);
};

export const addNewUser = (user: UserByOrg): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users`;
    return axios.post(url, user);
};

export const getUserById = (id?: any) => {
    let url = `${API_URL_USER}/users/${id}`;
    return axios.get(url);
};

export const deleteListUser = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params :{
            ids:ids?.toString()
        }
    }
    let url = `${API_URL_USER}/users/list-id`;
    return axios.delete(url,config);
};

export const deleteUser = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users/${id}`;
    return axios.delete(url);
};

export const getCurrentUser = (): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users/current`;
    return axios.get(url);
};

export const getPersonByUserId = (userId: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ADMIN}/person/by/user/${userId}`;
    return axios.get(url);
};

export const addNewPerson = (user: UserByOrg): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ADMIN}/person`;
    return axios.post(url, user);
};

export const updatePerson = (id: string, user: UserByOrg): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ADMIN}/person/${id}`;
    return axios.put(url, user);
};