import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import React, { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../component/dialog/OCTConfirmDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { PAGINATION } from "../../../Constant";
import { TYPE } from "../../utils/Constant";
import { Button, ButtonGroup } from "react-bootstrap";
import CustomTooltip from "../../component/CustomTooltip";
import MedicalEquipmentDialog from "./Dialog/MedicalEquipmentDialog";
import AppContext from "../../../AppContext";

type Props = {};

const MedicalEquipmentList = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [medicalEquipmentList, setMedicalEquipmentList] = useState<any[]>([]);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [id, setId] = useState<string>("");
  const [openMedicalEquipmentDialog, setOpenMedicalEquipmentDialog] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [medicalEquipmentInfo, setMedicalEquipmentInfo] = useState<any>({});
  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>
    },
    {
      name: "Mã thiết bị",
      field: "username",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: "left"
      }
      // render: (row) => <span>{row?.username}</span>,
    },
    {
      name: "Mã thiết bị BYT",
      field: "username",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: "left"
      }
      // render: (row) => <span>{row?.username}</span>,
    },
    {
      name: "Tên thiết bị",
      field: "roles",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
      // render: (row) => <span>{row?.roles?.toString()}</span>,
    },
    {
      name: "Model",
      field: "roles",
      cellStyle: {
        textAlign: "left"
      }
      // render: (row) => <span>{row?.email}</span>,
    },
    {
      name: "Serial",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Số lượng tối đa trong ngày",
      field: "lastLoginTime",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Số lượng",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Ghi chú",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Khoa sử dụng",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Năm sử dụng",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Giá tiền",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Số lần sửa chữa",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
    {
      name: "Nhà cung cấp",
      field: "lastLoginTime",
      cellStyle: {
        textAlign: "right"
      }
    },
  ];

  const actionsColumn: any[] = [
    {
      name: lang("TABLE.ACTION"),
      field: "action",
      isVisible: true,
      headerStyle: {
        width: 100
        // ...actionStyle,
      },
      cellStyle: {
        // width: 100,
        // ...actionStyle,
      },
      render: (rowData: any) => (
        <div>
          <ButtonGroup size="sm">
            <CustomTooltip title={lang("BTN.DELETE")}>
              <Button
                onClick={() => {
                  setShouldOpenConfirmDeleteDialog(true);
                  setId(rowData?.id);
                }}
                variant="outline-danger"
                className="hover-white"
              >
                <i className="bi bi-trash-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.EDIT")}>
              <Button
                onClick={() => {
                  // setIsView(false);
                  // handleEditUser?.(rowData);
                }}
                variant="outline-warning"
                className="hover-white"
              >
                <i className="bi bi-pencil-fill px-0"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.VIEW")}>
              <Button
                onClick={() => {
                  // setIsView(true);
                  // handleEditUser?.(rowData);
                }}
                variant="outline-primary"
                className="hover-white"
              >
                <i className="bi bi-eye-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
          </ButtonGroup>
        </div>
      )
    }
  ];

  const loadListService = async () => {
    // Your logic to fetch data
  };

  const handleOpenServiceDialog = () => {};

  const handleDelete = async (id: string) => {};

  const handleGetMedicalEquipmentList = async () => {
    try {
      setPageLoading(true);
      // const { data } = await getDSDichVuKyThuat(searchObject);
      // if (RESPONSE_STATUS_CODE.SUCCESS === data.code) {
      //   setMedicalEquipmentList(data?.data?.content);
      //   let totalElementsValue = data?.data?.totalElements || 0;
      //   let totalPageValue =
      //     totalElementsValue % searchObject?.pageSize === 0
      //       ? totalElementsValue / searchObject?.pageSize
      //       : Math.floor(totalElementsValue / searchObject?.pageSize) + 1;
      //   setTotalPage(totalPageValue || 0);
      //   setTotalElements(data?.data?.totalElements || 0);
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    handleGetMedicalEquipmentList();
  }, [searchObject])

  const handleOpenMedicalEquipmentDialog = () => {
    setOpenMedicalEquipmentDialog(true);
  };

  const handleCloseMedicalEquipmentDialog = () => {
    setOpenMedicalEquipmentDialog(false);
    setMedicalEquipmentInfo({});
    setIsView(false);
    handleGetMedicalEquipmentList();
  }

  return (
    <div>
      <OctTable
        title={lang("Danh sách trang thiết bị y tế")}
        data={medicalEquipmentList}
        columns={columns}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        isActionTableTab
        buttonAdd
        scrollable
        isSearchable
        handleSearch={loadListService}
        notDelete
        handleOpenDialog={handleOpenMedicalEquipmentDialog}
        totalPages={totalPage}
        totalElements={totalElements}
        type={TYPE.MULTILINE}
        actionsColumn={actionsColumn}
        className="custom-list-data"
      />
      {openMedicalEquipmentDialog && (
        <MedicalEquipmentDialog
          isView={isView}
          handleCloseDialog={handleCloseMedicalEquipmentDialog}
          medicalEquipmentInfo={medicalEquipmentInfo}
        />
      )}
      {shouldOpenConfirmDeleteDialog && (
        <ConfirmDialog
          show={shouldOpenConfirmDeleteDialog}
          title={"Xác nhận xóa"}
          message={"Bạn có muốn xóa không?"}
          yes={"Xác nhận"}
          onYesClick={() => handleDelete(id)}
          cancel={"Hủy"}
          onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
        />
      )}
    </div>
  );
};

export default MedicalEquipmentList;
