import { FC, useContext, useEffect, useState } from "react";
import { Org } from "../model/UserModels";
import { toast } from "react-toastify";
import { OrganizationDialog } from "./Dialog/OrganizationDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import {
  deleteOrganization,
  getListOrganizations,
  getOrganizationById,
} from "../services/OrganizationServices";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import { PAGINATION, PATH_ROUTES } from "../../../Constant";
import AppContext from "../../../AppContext";
import { OctTableCollapse, columnNamesTypeTableCollapse } from "@oceantech/oceantech-ui";
import { useNavigate } from "react-router-dom";
import { TYPE } from "../../utils/Constant";

const OrganizationList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const navigateTo = useNavigate();

  const [orgListData, setOrgListData] = useState<Org[]>([]);
  const [orgInfo, setOrgInfo] = useState<Org>({} as Org);
  const [openUnitDialog, setOpenUnitDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [numberOfElements, setNumberOfElements] = useState<number>(
    PAGINATION.NUMBER_OF_ELEMENTS_DEFAULT
  );
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  const orgDefaultPath = PATH_ROUTES.SYSTEM.ORGANIZATION;

  useEffect(() => {
    if (!openUnitDialog) {
      handleGetListOrganization();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize, openUnitDialog]);

  const handleOpenOrganizationDialog = () => {
    setOpenUnitDialog(true);
  };

  const handleCloseUnitDialog = () => {
    setOpenUnitDialog(false);
    setOrgInfo({} as Org);
    navigateTo(orgDefaultPath);
  };

  const handleGetListOrganization = async () => {
    try {
      setPageLoading(true);
      const res = await getListOrganizations(searchObject);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setOrgListData(data?.content);
        setTotalPage(data?.totalPages || 0);
        setTotalElements(data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.numberOfElements || 0);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleEditOrganization = async (row: any) => {
    try {
      setPageLoading(true);
      const res = await getOrganizationById(row?.id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        const result = {
          ...data,
        };

        navigateTo(orgDefaultPath + (data.path || ""));
        setOrgInfo(result);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      handleOpenOrganizationDialog();
    }
  };

  const handleDeleteOrganization = async (rowData: any) => {
    try {
      setPageLoading(true);
      const res = await deleteOrganization(rowData?.id);
      const { code } = res?.data;
      if (isSuccessfulResponse(code)) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      await handleGetListOrganization();
    }
  };

  const columns: columnNamesTypeTableCollapse[] = [
    {
      name: lang("UNIT.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "350px",
      },
      cellStyle: {
        minWidth: "350px",
      },
      isCollapse: true,
    },
    {
      name: lang("UNIT.CODE"),
      field: "code",
      type: TYPE.NUMBER,
      headerStyle: {
        minWidth: "120px",
        maxWidth: "150px",
      },
      cellStyle: {
        minWidth: "120px",
        maxWidth: "150px",
      },
    },
    {
      name: lang("UNIT.TYPE"),
      field: "type",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        minWidth: "150px",
      },
    },
  ];

  return (
    <div>
      <OctTableCollapse
        title={lang("UNIT.LIST")}
        data={orgListData}
        columns={columns}
        buttonAdd
        buttonEdit
        buttonDelete
        isSearchable
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        updateTableData={handleGetListOrganization}
        handleEdit={handleEditOrganization}
        handleDelete={handleDeleteOrganization}
        handleOpenDialog={handleOpenOrganizationDialog}
        nameChildren="subs"
        setData={(value: any) => {}}
        selectData={(value: any) => {}}
      />

      {openUnitDialog && (
        <OrganizationDialog
          open={openUnitDialog}
          handleCloseDialog={handleCloseUnitDialog}
          orgInfo={orgInfo}
        />
      )}
    </div>
  );
};
export { OrganizationList };
