import axios, {AxiosResponse} from "axios";
import {APIResponse} from "../../models/models";
import {Org} from "../model/UserModels";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/Constant";


const API_URL_ORG = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_XADMIN_ENDPOINT) + "/organizations";
const API_PATH_CATEGORY = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_XEMR_ENDPOINT) + "/simple-categories";
const API_ADMIN = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_XADMIN_ENDPOINT);

export const getListOrganizations = (searchData?: any): Promise<AxiosResponse<any>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || 10,
            pageIndex: searchData?.pageIndex || 1,
        },
    };
    let url = `${API_URL_ORG}/page/root`;
    return axios.get(url, config);
};

export const updateOrganization = (id: string, org: Org): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.put(url, org);
};

export const addNewOrganization = (org: Org): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}`;
    return axios.post(url, org);
};

export const getOrganizationById = (id?: any) => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.get(url);
};

export const getOrganizationByCode = (code: string): Promise<AxiosResponse<APIResponse<Org>>> => {
    let config = {
        params :{
            code
        }
    }
    let url = `${API_URL_ORG}/code`;
    return axios.get(url,config);
};

export const deleteOrganization = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.delete(url);
};

export const getSimpleCategory = (searchData?: any): Promise<AxiosResponse<any>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
            pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
            type: searchData?.type
        },
    };
    let url = `${API_PATH_CATEGORY}/search`;
    return axios.get(url, config);
};

export const searchDepartment = async (searchObject: any) => {
    let config = {
        params: searchObject,
    };
    const url = API_ADMIN + '/departments/search';  
    return axios.get(url, config);
  }