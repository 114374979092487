import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { RESPONSE_STATUS_CODE } from "../../utils/Constant";

export const VARIABLE = {
  FUNCTION_CONSTANT: {
    ERROR_MESSAGE: "errorMessage",
    MESSAGE: "message",
  },
};
export const COMPONENT_TYPE = {
  TEXTFIELD: "textfield",
  DATEPICKER: "datepicker",
  NUMBER: "number",
  SELECT: "select",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  TEXTAREA: "textarea",
  FILE: "file",
  COLUMNS: "columns",
  BUTTON: "button",
  PASSWORD: "password",
  SELECTBOXES: "selectboxes",
  DAY: "day",
  TIME: "time",
  EMAIL: "email",
  DATETIME: "datetime",
  PHONE: "phoneNumber",
  CONTENT: "content",
  HTML: "htmlelement"
};

export enum TYPE {
  OBJECT = "object",
  STATUS = "status",
  STRING = "string",
  TEXT = "text",
  NUMBER = "number",
  INPUT = "input",
  DATE = "date",
  TEXTAREA = "textarea",
  SELECT = "select",
  SINGLE = "single",
  MULTILINE = "multiline",
  MODULE = "module",
  PASSWORD = "password",
  EXCEL = "EXCEL",
  WORD = "WORD",
  MONEY = "MONEY",
  NUMBER_FLOAT = "NUMBER_FLOAT",
}

export interface IObject {
  [key: string]: any;
}

export const paramsConfig = (searchObject: object) => {
  return { params: searchObject };
};

export const replaceUrl = (url: string) => {
  if (!url) return "";
  let arr = url.split("/");
  switch (arr[0]) {
    case "{{adminResources}}":
      return url.replace(
        "{{adminResources}}",
        localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_API_URL as string
      );
    case "{{cisResources}}":
      return url.replace(
        "{{cisResources}}", localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL as string
      );
    default:
      return url;
  }
};

export const isSuccessfulResponse = (code: number) => RESPONSE_STATUS_CODE.SUCCESS === code;

export function TranslateMessage(messageId?: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId || "" });
}

const handleMessageByResponseCode = (code?: number) => {
  switch (code) {
    case RESPONSE_STATUS_CODE.FORBIDDEN:
      toast.warning(TranslateMessage("TOAST.ERROR.FORBIDDEN"));
      return true;
    case RESPONSE_STATUS_CODE.METHOD_NOT_ALLOWED:
      toast.warning(TranslateMessage("TOAST.ERROR.METHOD_NOT_ALLOWED"));
      return true;
    case RESPONSE_STATUS_CODE.CONFLICT:
      toast.warning(TranslateMessage("TOAST.ERROR.CONFLICT"));
      return true;
    default:
      return false;
  }
};

export const handleThrowResponseMessage = (res: any) => {
  // todo: Trả ra message theo 2 loại message của response
  const { message, data, code } = res?.data;
  const singleMessageKey = VARIABLE.FUNCTION_CONSTANT.ERROR_MESSAGE;
  const multipleMessageKey = VARIABLE.FUNCTION_CONSTANT.MESSAGE;

  toast.clearWaitingQueue();
  if (handleMessageByResponseCode(code)) {
    return;
  }

  let firstObject = data?.[0];
  if (Object.keys(firstObject).includes(singleMessageKey)) {
    //case:  data = [{errorMessage: "..."}]
    toast.warning(
      firstObject[singleMessageKey] ? firstObject[singleMessageKey] : message //case:  data = [{errorMessage: null}]
    );
  } else if (Object.keys(firstObject).includes(multipleMessageKey)) {
    data.forEach((x: any) => {
      toast.warning(x.field + ": " + x[multipleMessageKey]);
    });
  } else {
    toast.error("GENERAL.ERROR");
  }
};

export const API_KEY_EDITOR = "d10zlhug7bewrpg9sy5ffwn47xqwwwy9qp0u8gbnq41h0ldh";
