import {
  EmployeeProfile,
  IAuth,
  IRole,
  IUser,
  IuserByUnit,
} from "../../model/ManagerModel";

export const INIT_USER: IuserByUnit = {
  id: "",
  user: {
    username: "",
    password: "",
    confirmPassword: "",
    roles: [],
  } as IUser,
  employee: {} as EmployeeProfile,
  organization: null,
  orgId: "",
  createDate: "",
  createdBy: "",
  modifiedBy: "",
  modifyDate: "",
};

export const INIT_AUTH: IAuth = {
  id: "",
  orgId: "",
  name: "",
  code: "",
};

export const INIT_ROLE: IRole = {
  id: "",
  orgId: "",
  name: "",
  description: "",
  authorities: [],
};

export const findChildrenNamesByValue = (array: any[], value: string) => {
  let result: any[] = [];

  array.forEach((item) => {
    if (item.value.startsWith(value)) {
      if (item.children.length === 0) {
        result.push(item.value);
      } else {
        result = result.concat(findChildrenNamesByValue(item.children, value));
      }
    } else {
      if (item.children.length > 0) {
        result = result.concat(findChildrenNamesByValue(item.children, value));
      }
    }
  });
  return result;
};

export const INIT_DEPARTMENT = {
  name: "",
  code: "",
  orgId: "",
  type: null,
  level: null,
  organization: null,
  parentId: "",
};
