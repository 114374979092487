import React, { FC, useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { localStorageItem } from "../../utils/LocalStorage";
import { tab } from "../../models/tabModels";

type TabMenuProps = {
  danhsachTabs: tab[];
  handlerSelect?: (value: any) => void;
  keyDanhSachTabs?: string;
}

const TabContent = ({ activeTab, children }: any) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              setIsVisible(entry.isIntersecting);
          },
          { threshold: 0.1 }
      );

      if (ref.current) {
          observer.observe(ref.current);
      }

      return () => {
          if (ref.current) {
              observer.unobserve(ref.current);
          }
      };
  }, [activeTab]);

  return (
      <div ref={ref}>
          {isVisible && children}
      </div>
  );
};

export const TabMenu: FC<TabMenuProps> = (props) => {
  const { danhsachTabs, keyDanhSachTabs, handlerSelect } = props;
  let data = localStorageItem.get(keyDanhSachTabs) || [];
  const [activeTab, setActiveTab] = useState<string>("0");
  const [tabs, setTabs] = useState<tab[]>([]);

  useEffect(() => {
    setTabs(danhsachTabs);
  }, [danhsachTabs])

  const handleTabSelect: (eventKey: string | null) => void = (eventKey) => {
    if (eventKey) {
      handlerSelect && handlerSelect(eventKey || "");
      setActiveTab(eventKey);
    }
  };

  return (
    <Tabs
      className="tabs nav nav-tabs customs-tabs"
      activeKey={activeTab}
      onSelect={handleTabSelect}
    >
      {tabs.map((item, index) => {
        return (
          <Tab
            className="tab"
            eventKey={index}
            key={`${index}-${item.eventKey}`}
            title={
              <div className="label spaces pr-10 fw-bold">
                <span>{item?.title}</span>
              </div>
              // <div className={`${isCloseTab ? "spaces pr-10" : ""} label ${isDisabled ? "disabled-tab" : ""}`}>
              //   <span>{item?.title}</span>
              // </div>
            }
          >
            <TabContent activeTab={activeTab}>{item.component}</TabContent>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default TabMenu;
