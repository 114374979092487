import axios from "axios";
import { IChangePassword } from "../model/changePasswordModel";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
const API_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;

export const changePassword = (data: IChangePassword) => {
  const url = API_URL + "/user-organization/change-password";
  return axios.patch(url, data);
};
